import * as React from "react";
import { PageProps } from "gatsby";

import SEO from "../../components/seo";
import NavBar from "../../components/navBar";
import Footer from "../../components/Footer";
import AppStoreDownload from "../../components/AppStoreDownload";
import PlayStoreDownload from "../../components/PlayStoreDownload";

import ExampleLeaderboard from "../../images/example_leaderboard.jpg";
import NewGroup from "../../images/new_group.jpg";
import ExistingGroup from "../../images/existing_group.jpg";
import WurdleDownload from "../../images/wurdle_download.png";

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const DownloadWurdle = (props: PageProps) => {
  const params = new URLSearchParams(props.location.search);
  const groupId = params.get("id");

  return (
    <div style={pageStyles}>
      <SEO
        title="Download Wurdle with Friends"
        description="Enjoy Wordle but want to make it more social with your inner circle? Compete with friends in Wordle!"
        image={WurdleDownload}
      />
      <NavBar />
      <div className="py-16 bg-white overflow-hidden">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Wurdle with Friends
              </span>
            </h1>
            <div className="mt-6 text-xl text-gray-500 text-center">
              Join my Wurdle group and compete with me!
            </div>
          </div>
          <div className="mt-12 max-w-sm text-gray-500 mx-auto">
            <div className="text-gray-800 mb-2">
              <b>Steps:</b>
            </div>
            <div className="mb-2">
              <span className="text-gray-800">1.</span> Download the app
            </div>
            <div className="flex gap-4">
              <AppStoreDownload
                href="https://apps.apple.com/us/app/wurdle-with-friends/id1616260888"
                eventName="app_store_clicked_wurdle"
              />
              <PlayStoreDownload
                href="https://play.google.com/store/apps/details?id=io.one_step.wordle_tracker"
                eventName="play_store_clicked_wurdle"
              />
            </div>
            <div className="mb-2 mt-8">
              <span className="text-gray-800">2.</span> In the app, tap groups
              here
            </div>
            <img className="max-w-xs rounded-md" src={ExampleLeaderboard}></img>
            <div className="mb-2 mt-8">
              <span className="text-gray-800">3.</span> Select ⊕ button
            </div>
            <img className="max-w-xs rounded-md" src={NewGroup}></img>

            <div className="mb-2 mt-8">
              <span className="text-gray-800">4.</span> Copy this group ID:{" "}
              <b>{groupId ? groupId : "*Missing Group ID*"}</b>
            </div>
            <div className="mb-2 mt-8">
              <span className="text-gray-800">5.</span> Paste the group ID and
              press enter to join
            </div>
            <img className="max-w-xs rounded-md" src={ExistingGroup}></img>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DownloadWurdle;
